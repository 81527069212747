<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items"
  :querySearch="getData"
  isHiddenTableCount
  isHiddenPageCount
).px-4
  Form(slot="AddBlock")

</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SailorPassportStatement',
  components: {
    Form: () => import('./Form.vue')
  },
  data () {
    return {
      headers: [
        { value: 'date_create', text: this.$t('createDate'), sortable: false },
        { value: 'number', text: this.$t('number'), sortable: false },
        { value: 'port', text: this.$t('port'), sortable: false },
        { value: 'is_payed', text: this.$t('payment'), sortable: false },
        { value: 'status_document', text: this.$t('status'), sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          action: (item) => this.$router.push({ name: 'passports-statements-info', params: { ...this.$route.params, documentID: item.id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.sailor.sailorPassportStatement,
      isLoading: state => state.sailor.isLoading
    })
  },
  methods: {
    ...mapActions(['getSailorPassportStatements']),
    async getData (params) {
      this.getSailorPassportStatements({ ...this.$route.params, params })
    }

  }
}
</script>
